import ReadMore from "@components/ReadMore/ReadMore"
import Animation from "@components/elements/Animation"
import { communitiesURL } from "@lib/urls"
import { Link } from "gatsby"
import React from "react"
import "./PropertyAreaguide.scss"
import GGFXImage from "@components/elements/GGFXImage"

const PropertyAreaguide = ({ property, area }) => {
  return (
    <Animation
      animateInType="up"
      className="property-areaguide-wrap"
      id="areaGuidesection"
    >
      {area && (
        <>
          <p className="heading">{area.title} area guide</p>
          <div className="property-areaguide-section">
            {area.tile_image && (
              <Link
                to={`${communitiesURL + area.slug}/`}
                className="img-section img-zoom"
              >
                 <GGFXImage
          ImageSrc={area.tile_image}
          altText={`${area.title} area guide`}
          imagetransforms={area?.ggfx_results}
          renderer="srcSet"
          imagename="area-guide.tile_image.tile_img"
          strapiID={area?.strapi_id}
        />
                {/* <img
                  src={area.tile_image.url}
                  alt={`${area.title} area guide`}
                /> */}
              </Link>
            )}
            {area.content?.data?.content && (
              <div className="content-section">
                <ReadMore
                  className="read-more"
                  height={150}
                  content={area.content?.data?.content}
                  showMoreText="Continue Reading"
                  showLessText="Show Less"
                  scrollToId="areaGuidesection"
                />
              </div>
            )}
          </div>
          <div className="divider" />
        </>
      )}
      {property?.extra?.permit_number && (
        <div className="permit-section">
          {property?.qr_code && property?.qr_code?.length > 0 ?
          <div className="qr-code">
          <img src={property?.qr_code && property?.qr_code?.length > 0 ? property?.qr_code[0]?.validationQr: ""} alt="qr code" />
          </div> : null}
          <div className="content">
            <p className="title">DLD Permit Number</p>
            <p className="value">{property?.extra.permit_number}</p>
          </div>
        </div>
      )}
    </Animation>
  )
}

export default PropertyAreaguide
