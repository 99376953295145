import { getIconColor } from "@lib/utils"
import clsx from "clsx"
import React from "react"

const PhotoIcon = ({ className, color }) => {
  const stroke = getIconColor(color)

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("photo-icon", className)}
    >
      <path
        d="M1.5 10.5L4.93934 7.06066C5.52513 6.47487 6.47487 6.47487 7.06066 7.06066L10.5 10.5M9.5 9.5L10.4393 8.56066C11.0251 7.97487 11.9749 7.97487 12.5607 8.56066L14.5 10.5M2.5 13H13.5C14.0523 13 14.5 12.5523 14.5 12V4C14.5 3.44772 14.0523 3 13.5 3H2.5C1.94772 3 1.5 3.44772 1.5 4V12C1.5 12.5523 1.94772 13 2.5 13ZM9.5 5.5H9.505V5.505H9.5V5.5ZM9.75 5.5C9.75 5.63807 9.63807 5.75 9.5 5.75C9.36193 5.75 9.25 5.63807 9.25 5.5C9.25 5.36193 9.36193 5.25 9.5 5.25C9.63807 5.25 9.75 5.36193 9.75 5.5Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PhotoIcon
