import { getIconColor } from "@lib/utils"
import clsx from "clsx"
import React from "react"

const ArrowUpTray = ({ className, color }) => {
  const stroke = getIconColor(color)

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx("arrow-up-icon", className)}
    >
      <path
        d="M2 11V12.5C2 13.3284 2.67157 14 3.5 14H12.5C13.3284 14 14 13.3284 14 12.5V11M5 5L8 2M8 2L11 5M8 2L8 11"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ArrowUpTray
